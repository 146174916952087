import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ siteTitle, bleed }) => (
<header className="header">
  <nav className="nav-bar">
    <div className="container text-center">
      <Link to="/">{ siteTitle }</Link>
    </div>
  </nav>
</header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
